<template>
  <div>
    <div class="py-8 px-120px bg-grey-lighter mdmax:px-4">
      <div class="container flex flex-col gap-12 mdmax:gap-6">
        <div class="flex flex-col gap-2">
          <div class="text-3xl mdmax:text-2xl font-bold text-center">{{ $t('installment.requiredDoc.title') }}</div>
          <div class="text-center text-lg mdmax:text-base mdmax:text-center">{{ $t('installment.requiredDoc.desc') }}</div>
        </div>
        <div class="flex gap-5 mdmax:flex-col mdmax:gap-4">
          <div class="flex flex-col gap-4 bg-white p-6 mdmax:p-4 w-1/2 rounded-lg mdmax:w-full mdmax:border-2 border-2">
            <div class="flex flex-col gap-2">
              <div class="text-2xl font-bold mdmax:text-base">{{ $t('installment.requiredDoc.ktp.title') }}</div>
              <div class="text-sm pb-1">{{ $t('installment.requiredDoc.ktp.notes') }}</div>
            </div>
            <div class="flex flex-col gap-2">
              <div class="text-lg font-medium pb-1 mdmax:text-lg mdmax:font-medium">{{ $t('installment.requiredDoc.ktp.itemTitle') }}</div>
              <ul class="flex flex-col gap-1 text-base mdmax:text-sm">
                <li class="flex gap-2 items-start" v-for="guard in guardItems" :key="guard">
                  <div><div class="mt-2 ml-1 mr-2 w-2 h-2 rounded-full bg-primary-400" /></div>
                  <p>{{ guard }}</p>
                </li>
              </ul>
            </div>
          </div>
          <div class="flex flex-col gap-4 bg-white p-6 mdmax:p-4 w-1/2 rounded-lg mdmax:w-full mdmax:border-2 border-2">
            <div class="flex flex-col gap-2">
              <div class="text-2xl font-bold mdmax:text-base">{{ $t('installment.requiredDoc.payslip.title') }}</div>
              <ul class="flex flex-col gap-2 text-base">
                <li class="flex items-start gap-3" v-for="check in payslipCheck" :key="check">
                  <CheckMark color="#F7931E" width="43" />
                  <p class="c">{{ check }}</p>
                </li>
              </ul>
            </div>
            <div class="flex flex-col gap-2">
              <div class="text-lg font-medium pb-1 mdmax:text-base mdmax:font-medium mdmax:mt-2">{{ $t('installment.requiredDoc.payslip.itemTitle') }}</div>
              <ul class="flex flex-col gap-1 text-sm">
                <li class="flex gap-2 items-start" v-for="item in payslipItems" :key="item">
                  <div><div class="mt-2 ml-1 mr-2 w-2 h-2 rounded-full bg-primary-400" /></div>
                  <p>{{ item }}</p>
                </li>
              </ul>
            </div>
          </div>
          <div class="flex flex-col gap-4 bg-white p-6 mdmax:p-4 w-1/2 rounded-lg mdmax:w-full mdmax:border-2 border-2">
            <div class="text-2xl font-bold pb-1 mdmax:text-base mdmax:font-bold">{{ $t('installment.requiredDoc.invoice.title') }}</div>
            <ul class="flex flex-col gap-2 text-base list-disc">
              <li class="flex items-start gap-3" v-for="invoiceItem in invoiceCheck" :key="invoiceItem">
                <CheckMark color="#F7931E" width="20" />
                <p class="b">{{ invoiceItem }}</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    CheckMark: () => import(/* webpackChunkName: "icon" */ '@/components/icons/CheckMark')
  },
  computed: {
    activeLanguange() {
      return this.$i18n.locale
    },
    guardItems() {
      return this.$i18n.messages[this.activeLanguange].installment.requiredDoc.ktp.items
    },
    payslipCheck() {
      return this.$i18n.messages[this.activeLanguange].installment.requiredDoc.payslip.checklist
    },
    payslipItems() {
      return this.$i18n.messages[this.activeLanguange].installment.requiredDoc.payslip.items
    },
    invoiceCheck() {
      return this.$i18n.messages[this.activeLanguange].installment.requiredDoc.invoice.checklist
    }
  }
}
</script>

<style></style>
